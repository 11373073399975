import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PreferenceItem from './../components/PreferenceItem';
import WorksComponent from '../components/WorksComponent';
import ContactForm from '../components/ContactForm';

import { Scene3D } from '../components/background/background3D/Scene3D';
import { Scene2D } from '../components/background/background2D/Scene2D';
import {
  headerShape,
  experienseShape,
  workShape
} from '../components/background/background2D/shapesCollection';

import classes from '../components/background/styles/sceneBG.module.scss';

import '../styles/_home.scss';

import expertiseSrc from '../assets/expertise.svg';

import servicesSrc from '../assets/services.svg';
import logoSrc from '../assets/ExtejRender.png';

import section1Src from '../assets/section1.svg';
import section2Src from '../assets/section2.svg';
import section3Src from '../assets/section3.svg';

import backgroundImage2Src from '../assets/Section1Background.png';
import backgroundImage3Src from '../assets/Section3Backgroung.jpg';
import backgroundImage4Src from '../assets/Section6Background.png';
import VideoPreview from '../components/VideoPreview';
import { Experienced } from '../components/Experienced';
import { Approach } from '../components/Approach';

const Home = ({ portfolioItems, activeVideo, handleActiveVideoClick, others, isLoading }) => {
  const [navigatorAgent, setNavigatorAgent] = useState('');

  // get navigator only in usr
  useEffect(() => {
    setNavigatorAgent(navigator.userAgent);
  }, []);

  return (
    <>
      <Helmet>
        <title>Extej UI / UX design agency</title>
        <meta
          name="description"
          content="We are a UI UX design agency with expertise in fintech, crypto, Saas, DeFi product design, and FrontEnd / BackEnd development."
        />
        <link rel="canonical" href="https://www.extej.com/" />
      </Helmet>
      <div className="home">
        {/Safari/i.test(navigatorAgent) && !/Chrome/i.test(navigatorAgent) && (
          <img src={backgroundImage2Src} className="bg2" alt="bg" />
        )}
        <div className={classes.sceneBGHeader}>
          {!(/Safari/i.test(navigatorAgent) && !/Chrome/i.test(navigatorAgent)) && (
            <div className={classes.sceneBGHeader__scene2DSubHeader}>
              <Scene2D shapesCollection={headerShape} />
            </div>
          )}
        </div>

        <div className={classes.sceneBGHeader__scene3DHeader}>
          <Scene3D />
        </div>

        <div className="container">
          <main className="home-main">
            <div className="home-main__info">
              <h1>
                ADVANCED <span>DIGITAL</span> PRODUCTS
              </h1>
              <h2>Exceeding your expectations we create user-centered fintech product design</h2>
            </div>
            <div className="home-main__animate">
              {/Safari/i.test(navigatorAgent) && !/Chrome/i.test(navigatorAgent) ? (
                <img className="home-main__animate-logo" src={logoSrc} alt="logo" />
              ) : (
                <video width="100%" height="100%" autoPlay="1" muted>
                  <source src="animate.webm" type="video/webm" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </main>
          {!(/Safari/i.test(navigatorAgent) && !/Chrome/i.test(navigatorAgent)) && (
            <div className={classes.sceneBGExperience}>
              <div className={classes.sceneBGExperience__scene2DSubExperience}>
                <Scene2D shapesCollection={experienseShape} />
              </div>
            </div>
          )}
          <section className="home-experienced">
            <Experienced />
          </section>

          <div className="home-expertise">
            {/Safari/i.test(navigatorAgent) && (
              <img src={expertiseSrc} alt="expertise" />
            )}
          </div>

          <section className="home-approach">
            <h2>Approach</h2>
            <p>
              We dig deep into clients’ ideas and business requirements to develop revolutionary
              products from scratch. We can also redesign existing web platforms/apps to boost
              conversions and stand out from the competitors
            </p>

            <Approach />

            <p>
              The value of our agency lies in experienced specialists in the field of market
              research, analytics, strategy, UI UX design and Front & Back end development to ensure
              the success of all the products we create
            </p>
          </section>
          <div className="home-services">
            <img src={servicesSrc} alt="services" />
          </div>

          {/Safari/i.test(navigatorAgent) && !/Chrome/i.test(navigatorAgent) ? (
            <img src={backgroundImage3Src} className="bg3" alt="bg" />
          ) : (
            <div className={classes.scene2DWorks}>
              <Scene2D shapesCollection={workShape} width={750} height={550} />
            </div>
          )}
          
          <section className="home-technologies" id="home-technologies">
            <PreferenceItem
              imageSrc={section3Src}
              header={'UI/UX design'}
              technologies={['Strategy', 'Prototyping', 'User interface', 'Branding', 'Motion']}
              bg="BGOne"
            />
            <PreferenceItem
              imageSrc={section2Src}
              header={'Development'}
              technologies={[
                'FrontEnd',
                'BackEnd',
                'Quality Assurance',
                'Mobile App',
                'Maintenance'
              ]}
              bg="BGTwo"
            />
            <PreferenceItem
              imageSrc={section1Src}
              header={'Consulting'}
              technologies={[
                'UX/UI Review',
                'Market Research',
                'Usability Testing',
                'Design Workshop',
                'Technical Audit'
              ]}
              bg="BGThree"
            />
          </section>
          <section className="home-works" id="works">
            <h2 className="heading">Latest Works</h2>
            <WorksComponent
              portfolioItems={portfolioItems}
              others={others}
              handleActiveVideoClick={handleActiveVideoClick}
              isLoading={isLoading}
            />
          </section>
          <div className="home-bg4container">
            <img src={backgroundImage4Src} className="bg4" alt="bg" />
          </div>
          <section className="home-form" id="contacts">
            <h2 className="heading">Contact Us</h2>
            <ContactForm key={'regular'} />
          </section>
        </div>
        {activeVideo && (
          <VideoPreview
            activeVideo={activeVideo}
            handleActiveVideoClick={handleActiveVideoClick}
            others={others}
          />
        )}
      </div>
    </>
  );
};

export default Home;
