export const shape = (points, color, amplitude) => {
    
    const angles = points.map(() => Math.random() * 360);

    return (context, speed) => {
        
        // context.globalCompositeOperation = 'overlay';
        context.fillStyle = color;
        context.beginPath();
        context.moveTo(points[0].pointX, points[0].pointY);
        for(let i = 1; i < points.length - 1; i++){
            angles[i] += speed;
            context.lineTo(points[i].pointX + Math.cos(angles[i]) * amplitude.x, points[i].pointY + Math.sin(angles[i]) * amplitude.y);
        };
        context.fill();
    };
};