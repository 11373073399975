import React from "react";
import { Link } from "react-scroll";

import "../styles/_projectDetails.scss";

const ProjectDetails = ({ onClose, projectInfo }) => {
  const paragraphs =
    projectInfo.length > 0 &&
    projectInfo
      .split("//")
      .slice(0, -1)
      .map((el) => {
        return {
          label: el.split("::")[0],
          text: el.split("::")[1].split("/n"),
        };
      });

  return (
    <>
        <div className="projectDetails-wrap__content">
          <div className="projectDetails-wrap__content-sidebar">
            {paragraphs.map((paragraph, i) => {
              return (
                <Link
                  className="projectDetails-wrap__content-sidebar__p"
                  to={`title-${i}`}
                  containerId="content"
                  activeClass="active"
                  spy={true}
                  offset={-100}
                  key={i}
                >
                  {paragraph.label}
                </Link>
              );
            })}
          </div>
          <div className="projectDetails-wrap__content-main" id="content">
            {paragraphs.map((paragraph, i) => {
              return (
                <div
                  className={`projectDetails-wrap__content-main__p title-${i}`}
                  key={i}
                >
                  <span>{paragraph.label}</span>
                  {paragraph.text.map((a) => {
                    return <p key={a}>{a}</p>
                  })}
                </div>
              );
            })}
          </div>
        </div>
    </>
  );
};

export default ProjectDetails;
