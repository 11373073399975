import React, { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import { Select, MenuItem } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import WorkVideoPreview from "./WorkVideoPreview";

import "../styles/_worksComponent.scss";

import { WorkPagination } from "./WorkPagination";

const override = css`
  display: block;
  margin: 0 auto;
  border-width: 10px;
`;

export const OtherRenderContent = ({ othersItems, handleActiveVideoClick, isLoading }) => {

    const [{from, to}, setPageRange] = useState({from: 0, to: 3});
    const itemsPerPageAll = [3, 6, 9];
    const [itemsPerPage, setItemsPerPage] = useState(6);
    const pageRef = useRef(1);

    const others =
    othersItems &&
    othersItems.map(el => {
        return {
            dribble: el.attributes.dribbble,
            id: el.id,
            src: el.attributes.media.data[0].attributes.url,
            type: el.attributes.media.data[0].attributes.mime
        }
    });

    const handleChangeSelect = useCallback((event) => {
        setItemsPerPage(event.target.value);
    }, []);

    const calcRange = useCallback(() => {
        const from = (pageRef.current - 1) * itemsPerPage;
        const to = (pageRef.current - 1) * itemsPerPage + itemsPerPage;
        setPageRange({from: from, to: to});
    }, [itemsPerPage]);

    const handleItemsPageChange = useCallback((event, page) => {
        pageRef.current = page;
        calcRange();
    }, [calcRange]);

    useEffect(() => {
        calcRange();
    }, [calcRange, from, itemsPerPage, to]);

    const showItems = useMemo(() => {

        return <>
                <div className="works-other">
                    {
                        others.slice(from, to).map((item, i) => {
                            return <WorkVideoPreview key={i} {...item} handleActiveVideoClick={handleActiveVideoClick} />
                        })
                    }
                </div>
            </>
    }, [from, handleActiveVideoClick, others, to]);

    const theme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
            main: '#FF383D',
            },
        },
    });

    return (
        <>
            {
                (isLoading.isItemsLoading) 
                ? 
                <ClipLoader color="#ff383d" size={150} css={override} />
                :
                <>
                    { showItems }
                    <div className="works-pagination">
                        <ThemeProvider theme={theme}>
                            <div className="works-pagination-counter">
                                <Select
                                    id="pages-select"
                                    value={itemsPerPage}
                                    defaultValue={6}
                                    onChange={handleChangeSelect}
                                    size="small"
                                    sx={{
                                        height: "32px",
                                        "& .MuiSelect-iconOutlined": {
                                            color: "#FF383D"
                                        }
                                    }}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-list": {
                                                background: "#000",
                                                color: "#FF383D",
                                            },
                                            "& .Mui-selected": {
                                                background: "#FF383D!important",
                                                color: "#FFFFFF",
                                            },
                                        },
                                        PaperProps: {
                                            sx: {
                                                border: "1px solid #FF383D",
                                            }
                                        }
                                    }}
                                >
                                    {itemsPerPageAll.map(item => {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    })}
                                </Select>
                                <span>Works per page</span>
                            </div>
                            {others && others.length > 0 && 
                            <WorkPagination
                                key={"others"}
                                itemsLenght={others.length}
                                itemsPerPage={itemsPerPage}
                                onChange={handleItemsPageChange}
                            />}
                        </ThemeProvider>
                    </div>
                </>
            }
        </>
    );
};