import React from "react";
import { Link } from "react-scroll";
import { URL } from "./../url";
import axios from "axios";

import "../styles/_footer.scss";

import logoSrc from "../assets/logo.svg";

import tgSrc from "../assets/tg.svg";
import instaSrc from "../assets/insta.svg";
import dribbleSrc from "../assets/dribble.svg";
import behanceSrc from "../assets/behance.svg";
import facebookSrc from "../assets/facebook.svg";
import emailSrc from "../assets/email.svg";

const Footer = () => {
  const [socials, setSocials] = React.useState({});
  const currentYear = new Date().getFullYear();

  React.useEffect(() => {
    axios.get(`${URL}/api/socials/1`).then(({ data: res }) => {
      setSocials(res.data.attributes);
    });
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-head">
          <img className="logo" src={logoSrc} alt="logo" />
          <div className="wrap">
            <ul className="footer-head__list">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/">Services</a>
              </li>
              <li>
                <a href="/">Works</a>
              </li>
            </ul>
            <div className="contacts-wrap">
              <div className="contacts">
                <span>Contact Us:</span>
                <a href={socials.telegram}>
                  <img src={tgSrc} alt="tg icon" id="tg" />
                </a>
                <a href={`mailto:${socials.email}`}>
                  <img src={emailSrc} alt="email icon" id="email" />
                </a>
              </div>
              <div className="contacts">
                <span>Follow Us:</span>
                <a href={socials.behance}>
                  <img src={behanceSrc} alt="icon" id="behance" />
                </a>
                <a href={socials.dribble}>
                  <img src={dribbleSrc} alt="icon" id="dribble" />
                </a>
                <a href={socials.instagram}>
                  <img src={instaSrc} alt="icon" id="insta" />
                </a>
                <a href={socials.facebook}>
                  <img src={facebookSrc} alt="icon" id="facebook" />
                </a>
              </div>
            </div>
          </div>
          <Link to="header" smooth="true" duration={500} offset={-150}>
            <div className="arrowBtn">
              <div className="arrow-wrap">
                <div className="arrow"></div>
              </div>
            </div>
          </Link>
        </div>
        <div className="footer-copyright">
          Copyright {currentYear} © Extej. All rights reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
