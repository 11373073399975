import React from "react";

import "../styles/_workVideoPreview.scss";

import searchSrc from "../assets/icons/search.png";

const WorkVideoPreview = ({ src, id, handleActiveVideoClick, type }) => {
  return type === "video/webm" ? (
    <div
      className="workVideoPreview"
      onClick={() => handleActiveVideoClick(id)}
    >
      <video key={src} className="media" width="100%" height="100%" loop muted>
        <source src={`${src}`} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <img className="workVideoPreview__img" src={searchSrc} alt="search" />
    </div>
  ) : (
    <div
      className="workVideoPreview"
      onClick={() => handleActiveVideoClick(id)}
    >
      <img className="media" src={`${src}`} alt="img" />
      <img className="workVideoPreview__img" src={searchSrc} alt="search" />
    </div>
  );
};

export default WorkVideoPreview;
