import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from '@react-three/fiber';

export function Model(props) {
  const { nodes } = useGLTF('/models/Spiral.gltf');
  const color = props.color;
  const modelRef = useRef();

  useFrame(() => {
    modelRef.current.rotation.z += 0.003;
  });

  return (
    <group {...props} ref={modelRef} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        rotation={[0, 0, -0.3]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        rotation={[0, 0, 0.16]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube003.geometry}
        rotation={[0, 0, 1.04]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        rotation={[0, 0, 0.59]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube007.geometry}
        rotation={[0, 0, 2.35]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006.geometry}
        rotation={[0, 0, 1.49]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008.geometry}
        rotation={[0, 0, -3.04]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube005.geometry}
        rotation={[0, 0, 1.93]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube010.geometry}
        rotation={[0, 0, -2.1]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube011.geometry}
        rotation={[0, 0, -1.67]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube009.geometry}
        rotation={[0, 0, 2.81]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube012.geometry}
        rotation={[0, 0, -2.56]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube015.geometry}
        rotation={[0, 0, -0.76]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013.geometry}
        rotation={[0, 0, -1.22]}
        scale={[1.01, 0.66, 0.83]}
      >
        <meshStandardMaterial color={color}/>
      </mesh>
    </group>
  );
}

useGLTF.preload('/models/Spiral.gltf');