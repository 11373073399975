import React, { useState, useCallback, useMemo, useEffect, useRef } from "react";
import urlSlug from 'url-slug';
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import { Select, MenuItem } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import "../styles/_worksComponent.scss";

import WorkDetailed from "./WorkDetailed";
import { WorkPagination } from "./WorkPagination";
import { WorkModal } from "./Modal";

const override = css`
  display: block;
  margin: 0 auto;
  border-width: 10px;
`;

export const WorkRenderContent = ({ portfolioItems, isLoading }) => {

    const [{from, to}, setPageRange] = useState({from: 0, to: 2});
    const itemsPerPageAll = [2, 3, 4, 5, 6];
    const [itemsPerPage, setItemsPerPage] = useState(2);
    const pageRef = useRef(1);

    const [isOpen, setIsOpen] = useState(false);

    const items = 
    portfolioItems &&
    portfolioItems.map((item) => {
        const { title, cover, tags, description, video } = item.attributes;
        const { id } = item;
        const linkId = item.attributes.extended_item.data.id;
        const workUrl = urlSlug(title);
        const imageSrc = cover.data.attributes.url;
        return {
            title,
            imageSrc,
            tags,
            description,
            id,
            linkId,
            workUrl,
            video: video.data?.attributes.url
        };
    });

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleChangeSelect = useCallback((event) => {
        setItemsPerPage(event.target.value);
    }, []);

    const calcRange = useCallback(() => {
        const from = (pageRef.current - 1) * itemsPerPage;
        const to = (pageRef.current - 1) * itemsPerPage + itemsPerPage;
        setPageRange({from: from, to: to});
    }, [itemsPerPage]);

    const handleItemsPageChange = useCallback((event, page) => {
        pageRef.current = page;
        calcRange();
    }, [calcRange]);

    useEffect(() => {
        calcRange();
    }, [calcRange, from, itemsPerPage, to]);

    const showItems = useMemo(() => {
            return <>
                <div className="works-detailed">
                    {items &&
                        items.slice(from, to).map((item, i) => {
                            return <WorkDetailed work={item} key={item.id} onClick={handleOpen} />;
                        })
                    }
                </div>
            </>

    }, [from, handleOpen, items, to]);

    const theme = createTheme({
        palette: {
                mode: 'dark',
                primary: {
                main: '#FF383D',
            },
        },
    });

    return (
        <>
            {
                (isLoading.isItemsLoading) 
                ? 
                <ClipLoader color="#ff383d" size={150} css={override} />
                :
                <>
                    <WorkModal portfolioItems={portfolioItems} onClick={handleClose}/>
                    { showItems }
                    <div className="works-pagination">
                        <ThemeProvider theme={theme}>
                            <div className="works-pagination-counter">
                                <Select
                                    id="pages-select"
                                    value={itemsPerPage}
                                    defaultValue={2}
                                    onChange={handleChangeSelect}
                                    size="small"
                                    sx={{
                                        height: "30px",
                                        "& .MuiSelect-iconOutlined": {
                                            color: "#FF383D"
                                        }
                                    }}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-list": {
                                                background: "#000",
                                                color: "#FF383D",
                                            },
                                            "& .Mui-selected": {
                                                background: "#FF383D!important",
                                                color: "#FFFFFF",
                                            },
                                        },
                                        PaperProps: {
                                            sx: {
                                                border: "1px solid #FF383D",
                                            }
                                        }
                                    }}
                                >
                                    {itemsPerPageAll.map(item => {
                                        return <MenuItem 
                                                key={item} 
                                                value={item}>{item}
                                            </MenuItem>
                                    })}
                                </Select>
                                <span>Works per page</span>
                            </div>
                            {items && items.length > 0 &&
                            <WorkPagination
                                key={"items"}
                                itemsLenght={items.length}
                                itemsPerPage={itemsPerPage}
                                onChange={handleItemsPageChange}
                            />}
                        </ThemeProvider>
                    </div>
                </>
            }
        </>
    );
};
