import React from "react";
import { Link } from "react-scroll";

import "../styles/_preferenceItem.scss";

const PreferenceItem = ({ imageSrc, header, technologies, bg }) => {
  return (
    <div className="item">
      <div className={`item-wrap ${bg}`}>
        <img src={imageSrc} alt="item" />
        <h3>{header}</h3>
        <ul>
          {technologies &&
            technologies.map((t, i) => {
              return <li key={i}>{t}</li>;
            })}
        </ul>
        <Link to="contactForm" offset={-150} smooth="true" duration={500}>
          <button className="item-btn primary-btn">Get started<span></span></button>
        </Link>
      </div>
    </div>
  );
};

export default PreferenceItem;
