import React from "react";
import { Link } from "react-router-dom";

import "../styles/_workDetailed.scss";

const WorkDetailed = ({ work, onClick }) => {
  const { title, imageSrc, tags, description, workUrl } = work;

  const paragraphs =
    description.length > 0 &&
    description
      .split("//")
      .slice(0, -1)
      .map((el) => {
        return {
          label: el.split("::")[0],
          text: el.split("::")[1],
        };
      });

  return (
    <div className={`workDetailed`}>
      <div className="workDetailed__img">
        <Link to={`/works/${workUrl}`}>
            <img src={`${imageSrc}`} onClick={onClick} alt="cover" loading="lazy"/>
        </Link>
      </div>
      <div className="workDetailed__info">
        <div className="workDetailed__info-wrap">
          <h2>{title}</h2>
          <ul>
            {tags.split(",").map((tag, i) => {
              return <li key={i}>#{tag.trim()}</li>;
            })}
          </ul>
          {paragraphs.map((paragraph, i) => {
            const { label, text } = paragraph;
            return (
              <p key={i}>
                <span>{label.trim()}: </span>
                {text}
              </p>
            );
          })}
          <Link to={`/works/${workUrl}`} style={{textDecoration: 'none'}}>
            <button 
              className="workDetailed__info-wrap primary-btn" 
              onClick={onClick}
            >
              Learn more
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WorkDetailed;
