import React from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';
import { URL } from '../url';

import '../styles/_header.scss';

import logoSrc from '../assets/logo.svg';
import tgSrc from '../assets/tg.svg';
import instaSrc from '../assets/insta.svg';
import dribbleSrc from '../assets/dribble.svg';
import behanceSrc from '../assets/behance.svg';
import facebookSrc from '../assets/facebook.svg';
import emailSrc from '../assets/email.svg';

const Header = () => {
  const [activeMenu, setActiveMenu] = React.useState(false);
  const [socials, setSocials] = React.useState({});

  React.useEffect(() => {
    axios.get(`${URL}/api/socials/1`).then(({ data: res }) => {
      setSocials(res.data.attributes);
    });
  }, []);

  React.useEffect(() => {
    if (activeMenu) {
      const x = window.pageXOffset;
      const y = window.pageYOffset;
      window.onscroll = function () {
        window.scrollTo(x, y);
      };
    } else {
      window.onscroll = function () {};
    }
  }, [activeMenu]);

  const mediaQuery = window.matchMedia('(min-width: 768px)');

  return (
    <div className="container">
      <header className={`header ${activeMenu ? 'active' : ''}`}>
        <NavLink exact to="/" className="link" smooth="true" duration={500}>
          <img className="header-logo" src={logoSrc} alt="logo" />
        </NavLink>
        <ul className={`header-menu ${activeMenu ? 'active' : ''}`}>
          <li>
            <NavLink
              exact
              to="/"
              className="link"
              smooth="true"
              duration={500}
              onClick={() => mediaQuery.matches || setActiveMenu(!activeMenu)}>
              Home
            </NavLink>
          </li>
          <li>
            <HashLink
              to="/#home-technologies"
              className="link"
              smooth="true"
              duration={500}
              onClick={() => mediaQuery.matches || setActiveMenu(!activeMenu)}>
              Services
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/#works"
              className="link"
              smooth="true"
              duration={500}
              offset={-100}
              onClick={() => mediaQuery.matches || setActiveMenu(!activeMenu)}>
              Works
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/#contacts"
              className="link"
              smooth="true"
              duration={500}
              offset={-50}
              onClick={() => mediaQuery.matches || setActiveMenu(!activeMenu)}>
              Contact us
            </HashLink>
          </li>
        </ul>
        <p
          className={`header-hidden-menu ${activeMenu ? 'active' : ''}`}
          onClick={() => setActiveMenu(!activeMenu)}>
          <span>✕</span> Menu
        </p>
        <div
          className={`header-hidden__footer ${activeMenu ? 'active' : ''}`}
          style={{ display: activeMenu ? 'flex' : 'none' }}>
          <div className="header-hidden__footer__col">
            <div className="header-hidden__footer__col-p">Contacts: </div>
            <a href={socials.telegram}>
              <img src={tgSrc} alt="tg icon" id="tg" />
            </a>
            <a href={`mailto:${socials.email}`}>
              <img src={emailSrc} alt="email icon" id="email" />
            </a>
          </div>
          <div className="header-hidden__footer__col">
            <div className="header-hidden__footer__col-p">Follow us: </div>
            <div className="header-hidden__footer__col-socials">
              <a href={socials.behance}>
                <img src={behanceSrc} alt="icon" id="behance" />
              </a>
              <a href={socials.dribble}>
                <img src={dribbleSrc} alt="icon" id="dribble" />
              </a>
              <a href={socials.instagram}>
                <img src={instaSrc} alt="icon" id="insta" />
              </a>
              <a href={socials.facebook}>
                <img src={facebookSrc} alt="icon" id="facebook" />
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
