import experiencedAtSrc from '../assets/experiencedAtTransparent.svg';

import '../styles/_experiencedShapes.scss';

export const Experienced = () => {
  return (
    <div className='experiencedBox'>
      <img src={experiencedAtSrc} alt="experienced at" className='experiencedBox__shapesImg' />

      <h3 className='experiencedBox__label' style={{top: '25%', left: '11.1%'}}>CRYPTO</h3>
      <h3 className='experiencedBox__label' style={{top: '25%', left: '33.5%'}}>DEFI</h3>

      <h3 className='experiencedBox__label' style={{top: '63%', left: '12.5%'}}>CBDC</h3>
      <h3 className='experiencedBox__label' style={{top: '63%', left: '31.1%'}}>TRADING</h3>

      <h3 className='experiencedBox__label' style={{top: '25%', left: '59%'}}>DASHBOARD</h3>
      <h3 className='experiencedBox__label' style={{top: '25%', left: '81%'}}>WALLETS</h3>

      <h3 className='experiencedBox__label' style={{top: '63%', left: '58.5%'}}>MOBILE APPS</h3>
      <h3 className='experiencedBox__label' style={{top: '63%', left: '79.5%'}}>INVESTINGS</h3>
    </div>
  );
};
