import React from 'react';
import axios from 'axios';
import urlSlug from 'url-slug';
import { Helmet } from 'react-helmet';
import { useHistory, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { URL } from './../url';

import ProjectDetails from './../components/ProjectDetails';
import ContactForm from '../components/ContactForm';

import '../styles/_workPreview.scss';

import arrowSrc from '../assets/icons/arrow.png';
import infoSrc from '../assets/icons/info.png';
import infoSvgSrc from '../assets/icons/info.svg';
import closeSrc from '../assets/icons/close.png';
import behanceSrc from '../assets/behance.svg';

const WorkPreview = ({ portfolioItems, onClick, toInfo }) => {
  const [portfolioItem, setPortfolioItem] = React.useState({});
  const [nextItem, setNextItem] = React.useState(null);
  const [prevItem, setPrevItem] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (portfolioItems.length > 0) {
      const location = history.location.pathname;
      const currentSlug = location.split('/').slice(-1)[0];
      const currentItem = portfolioItems.findIndex(
        (a) => urlSlug(a.attributes.title) === currentSlug
      );
      const currentItemId = portfolioItems[currentItem].id;
      const nextItem = currentItem + 1 > portfolioItems.length - 1 ? 0 : currentItem + 1;
      const prevItem = currentItem - 1 < 0 ? portfolioItems.length - 1 : currentItem - 1;
      setNextItem(urlSlug(portfolioItems[nextItem].attributes.title));
      setPrevItem(urlSlug(portfolioItems[prevItem].attributes.title));

      axios.get(`${URL}/api/extended-items/${currentItemId}?populate=*`).then(({ data: res }) => {
        const extendedImage = res.data.attributes.extendedImage.data
          .map((image) => {
            return {
              id: image.id,
              height: image.attributes.height,
              mime: image.attributes.mime,
              name: image.attributes.name,
              url: image.attributes.url
            };
          })
          .sort(
            (a, b) =>
              a.name.split('.')[0].split('_').reverse()[0] -
              b.name.split('.')[0].split('_').reverse()[0]
          );

        const item = {
          title: res.data.attributes.portfolio_item.data.attributes.title,
          date: res.data.attributes.portfolio_item.data.attributes.date,
          aboutProject: res.data.attributes.aboutProject,
          metaTitle: res.data.attributes.portfolio_item.data.attributes.metaTitle,
          metaDescription: res.data.attributes.portfolio_item.data.attributes.metaDescription,
          extendedImage
        };
        setPortfolioItem(item);
      });
    }
  }, [history.location.pathname, portfolioItems]);

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'auto');
  }, []);

  return (
    <div className="workPreview">
      <Helmet>
        <title>{portfolioItem.metaTitle}</title>
        <meta name="description" content={portfolioItem.metaDescription} />
        <link rel="canonical" href={`https://www.extej.com${history.location.pathname}`} />
      </Helmet>

      {/* <div className="container"> */}
      <div className="workPreview-wrap">
        <div className="workPreview-wrap__nav left">
          {prevItem && (
            <Link className="secondary-btn arrow" to={`/works/${prevItem}`}>
              <img src={arrowSrc} alt="arrow" />
            </Link>
          )}
        </div>
        <div className="workPreview-wrap__content">
          <div className="workPreview-wrap__content__title">
          {portfolioItem.title}
          </div>

          <div className="workPreview-wrap__content-section">
            <div className="workPreview-wrap__content-mobileOnly">
              <a className="behance-link" href="https://www.behance.net/extejagency">
                <img src={behanceSrc} alt="icon" id="behance" />
              </a>
              <div className="arrowBtn" onClick={toInfo}>
                <div className="arrow-wrap">
                  <img className="info" src={infoSrc} alt="info" />
                </div>
              </div>
              {prevItem && (
                <Link className="secondary-btn arrow left" to={`/works/${prevItem}`}>
                  <img src={arrowSrc} alt="arrow" />
                </Link>
              )}
              {nextItem && (
                <Link className="secondary-btn arrow" to={`/works/${nextItem}`}>
                  <img src={arrowSrc} alt="arrow" />
                </Link>
              )}
              <HashLink className="secondary-btn close-btn" to={'/#works'}>
                <img src={closeSrc} alt="close" onClick={onClick} />
              </HashLink>
            </div>
            <div className="projectDetails-wrap__content-sidebody">
              <div className="workPreview-wrap__content-body">
                {portfolioItem.extendedImage &&
                  portfolioItem.extendedImage.map((image) => {
                    if (image.mime === 'image/png' || image.mime === 'image/jpeg') {
                      return (
                        <img
                          src={`${image.url}`}
                          alt="extendedImage"
                          loading="lazy"
                          key={image.url}
                        />
                      );
                    } else {
                      return (
                        <video
                          width="100%"
                          height="100%"
                          loop
                          muted
                          autoPlay
                          style={{ display: 'block' }}
                          key={image.url}>
                          <source src={`${image.url}`} type="video/webm" />
                          Your browser does not support the video tag.
                        </video>
                      );
                    }
                  })}
              </div>
            </div>
          </div>

          <div className="workPreview-head">
            <img className="info" src={infoSvgSrc} alt="info" />
            <h1 className="title">{portfolioItem.title}</h1>
            <span className="date">{portfolioItem.date}</span>
          </div>
          {portfolioItem.aboutProject && (
            <ProjectDetails projectInfo={portfolioItem.aboutProject} />
          )}
          <h2 className="heading">Contact Us</h2>
          <ContactForm />
        </div>
        <div className="workPreview-wrap__nav right">
          <HashLink className="secondary-btn close-btn" to={'/#works'} onClick={onClick}>
            <img src={closeSrc} alt="close" />
          </HashLink>
          <a className="behance-link" href="https://www.behance.net/extejagency">
            <img src={behanceSrc} alt="icon" id="behance" />
          </a>
          <div className="arrowBtn" onClick={toInfo}>
            <div className="arrow-wrap">
              <img className="info" src={infoSrc} alt="info" />
            </div>
          </div>
          {nextItem && (
            <Link className="secondary-btn arrow" to={`/works/${nextItem}`}>
              <img src={arrowSrc} alt="arrow" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkPreview;
