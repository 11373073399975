import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { Model } from './Model';

export const Scene3D = () => {
    return (
        <Canvas shadows dpr={[1, 2]} camera={{ fov: 1 }}>
            <Suspense fallback={null}>
                <ambientLight intensity={0.6} />
                <directionalLight color='white' position={[-10, 14, 5]} intensity={0.3}/>
                
                <Model
                    color={'#E465F9'}
                    position={[-0.3, 0.32, -50]}
                    rotation={[-0.3, -0.8, 0]}
                    scale={[0.21, 0.21, 0.35]}
                />
                <Model
                    color={'#EA48E2'}
                    position={[-0.45, -0.1, -60]}
                    rotation={[-0.2, -1, 0]}
                    scale={[0.21, 0.21, 0.37]}
                />
            </Suspense>
        </Canvas>
    );
};