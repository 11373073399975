import React, { memo, useState} from "react";
import deepEqual from "../helpers/deepEqual";

import { WorkRenderContent } from "./WorkRenderContent";
import { OtherRenderContent } from "./OtherRenderContent";

const WorksComponent = memo(({ portfolioItems, others, handleActiveVideoClick, isLoading }) => {
  const [activeBtn, setActiveBtn] = useState(0);
  const buttons = [
    { label: "All" },
    { label: "Detailed Presentations" },
    { label: "Other" },
  ];

  return (
    <div className="works">
      <div className="works-buttons">
        {buttons.map((btn, i) => {
          const { label } = btn;
          return (
            <div
              id={i}
              onClick={() => setActiveBtn(i)}
              className={`works-buttons__button ${activeBtn === i ? "active" : ""}`}
              key={label}
            >
              <div className={`img-${i}`}></div>
              <p>{label}</p>
            </div>
          );
        })}
        <hr />
      </div>
      {(activeBtn === 0) && <>
          <WorkRenderContent 
            portfolioItems={portfolioItems}
            isLoading={isLoading}
          />
          <OtherRenderContent
            othersItems={others}
            handleActiveVideoClick={handleActiveVideoClick}
            isLoading={isLoading}
          />
        </>
      }
      {(activeBtn === 1) && <WorkRenderContent 
        portfolioItems={portfolioItems}
        isLoading={isLoading}
      />}
      {(activeBtn === 2) && <OtherRenderContent 
        othersItems={others}
        handleActiveVideoClick={handleActiveVideoClick}
        isLoading={isLoading}
      />}
    </div>
  );
}, (prev, next) => {
  return prev.isLoading === next.isLoading && deepEqual(prev.portfolioItems, next.portfolioItems);
});

export default WorksComponent;
