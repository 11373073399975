import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

import "./styles/index.scss";

const root = ReactDOM.hydrateRoot(
  document.getElementById('root'),
);

root.render(
  <Router>
      <App />
  </Router>,
);
