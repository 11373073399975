import approachArrow from '../assets/approachArrow.svg';
import approachCircles from '../assets/approachCircles.svg';
import approachCirclesReversed from '../assets/approachCirclesReversed.svg';

import '../styles/_approach.scss';

export const Approach = () => {
  const inViewport = (entries, observer) => {
    entries.forEach((entry) => {
      entry.target.classList.toggle('is-inViewport', entry.isIntersecting);
    });
  };

  const Obs = new IntersectionObserver(inViewport);
  const obsOptions = {}; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options

  // Attach observer to every [data-inviewport] element:
  document.querySelectorAll('[data-inviewport]').forEach((el) => {
    Obs.observe(el, obsOptions);
  });

  return (
    <div className="approach">
      <div className="approach__item">
        <img src={approachCircles} alt="approach" data-inviewport="circle1" style={{ margin: 0 }} />

        <h4 className="approach__item__label">Stakeholders</h4>
      </div>

      <div className="approach__item">
        <img
          src={approachCirclesReversed}
          alt="approach"
          data-inviewport="circle2"
          style={{ margin: 0 }}
        />

        <h4 className="approach__item__label">Ux Design</h4>
      </div>

      <div className="approach__item">
        <img src={approachCircles} alt="approach" data-inviewport="circle3" style={{ margin: 0 }} />

        <h4 className="approach__item__label">Ui Design</h4>
      </div>

      <div className="approach__item">
        <img
          src={approachCirclesReversed}
          alt="approach"
          data-inviewport="circle4"
          style={{ margin: 0 }}
        />

        <h4 className="approach__item__label">Development & QA</h4>
      </div>

      {/* --------------------arrow & titles above----------------------- */}
      <div className="approach__label left" data-inviewport="labelTopLeft">
        <img
          src={approachArrow}
          alt="approachArrow"
          className="approach__label__arrow"
          style={{ margin: 0 }}
        />

        <h5 className="approach__label__title">Goals + Requirements</h5>
      </div>

      <div className="approach__label center" data-inviewport="labelTopCenter">
        <img
          src={approachArrow}
          alt="approachArrow"
          className="approach__label__arrow"
          style={{ margin: 0 }}
        />

        <h5 className="approach__label__title">Rapid Prototype</h5>
      </div>

      <div className="approach__label right" data-inviewport="labelTopRight">
        <img
          src={approachArrow}
          alt="approachArrow"
          className="approach__label__arrow"
          style={{ margin: 0 }}
        />

        <h5 className="approach__label__title">Hi-fi Clickable Mock-Ups</h5>
      </div>

      {/* --------------------arrow & titles bottom----------------------- */}

      <div className="approach__labelBottom left"  data-inviewport="labelTopLeft">
        <img
          src={approachArrow}
          alt="approachArrow"
          className="approach__label__arrow bottom"
          style={{ margin: 0 }}
        />

        <h5 className="approach__label__title">User Experience Strategy</h5>
      </div>

      <div className="approach__labelBottom center" data-inviewport="labelTopCenter">
        <h5 className="approach__label__title">Hi-fi Clickable Mock-Ups</h5>
      </div>

      <div className="approach__labelBottom right" data-inviewport="labelTopRight">
        <h5 className="approach__label__title">FrontEnd & BackEnd</h5>
      </div>
    </div>
  );
};
