import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import ReCAPTCHA from 'react-google-recaptcha';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';

import '../styles/_contactForm.scss';

import section1Src from '../assets/section1.svg';
import section2Src from '../assets/section2.svg';
import section3Src from '../assets/section3.svg';
// import axios from "axios";

// const postURL = "https://extej-mailsender.herokuapp.com/";
// const EMAIL = "extej.work@gmail.com";

const initialValues = {
  email: '',
  name: '',
  phone: '',
  company: '',
  description: ''
};

const override = css`
  display: block;
  border-width: 10px;
`;

const ContactForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(false);
  const [activeBtn, setActiveBtn] = React.useState(0);
  const [values, setValues] = React.useState(initialValues);
  const [state, handleSubmit] = useForm('mdobbvwp');
  const buttons = [
    { src: section3Src, label: 'UI/UX design' },
    { src: section2Src, label: 'Development' },
    { src: section1Src, label: 'Consulting' }
  ];

  const handleCaptchaVerify = (value) => {
    setCaptcha(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value
    });
  };

  const handleFormSubmit = (e) => {
    if (!captcha) {
      alert('Please fill the captcha');
    } else {
      handleSubmit(e);
      alert('Email successfully sent');
      setValues(initialValues);
    }
  };

  return (
    <div className="contactForm">
      <div className="contactForm-header">
        {buttons.map((btn, i) => {
          return (
            <div
              className={`contactForm-header__button ${activeBtn === i ? 'active' : ''}`}
              onClick={() => setActiveBtn(i)}
              key={btn.label}>
              <img src={btn.src} alt="img" />
              <span className="contactForm-header__button-span">{btn.label}</span>
            </div>
          );
        })}

        <hr />
      </div>
      <form className="contactForm-content" onSubmit={(e) => handleFormSubmit(e)}>
        <div className="contactForm-content__loader" style={{ display: loading ? 'flex' : 'none' }}>
          <ClipLoader color="#ff383d" size={150} css={override} />
        </div>
        <div className="contactForm-content__wrap">
          <div className="contactForm-content__wrap-col">
            <label>
              <span>Email</span>
              <input
                className="primary-input"
                type="email"
                placeholder="Enter your Email"
                required
                name="email"
                value={values.email}
                onChange={handleInputChange}
              />
            </label>
            <label>
              <span>Phone</span>
              <input
                className="primary-input"
                type="text"
                placeholder="Enter your Phone (Optional)"
                name="phone"
                value={values.phone}
                onChange={handleInputChange}
              />
            </label>
            <label>
              <span>Name</span>
              <input
                className="primary-input"
                type="text"
                placeholder="Enter your Name"
                required
                name="name"
                value={values.name}
                onChange={handleInputChange}
              />
            </label>
            <label>
              <span>Company</span>
              <input
                className="primary-input"
                type="text"
                placeholder="Enter your Company (Optional)"
                name="company"
                value={values.company}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="contactForm-content__wrap-col">
            <label className="textarea">
              <span>Description</span>
              <textarea
                className="primary-input"
                placeholder="Briefly describe the idea or problem"
                required
                minLength={10}
                name="description"
                value={values.description}
                onChange={handleInputChange}
              />
            </label>
          </div>
        </div>
        <div className="contactForm-content__buttons">
          <ReCAPTCHA
            sitekey="6LdGYzsfAAAAACdQdPfeiBGUL8yn39XF8tsTTeeV"
            onChange={handleCaptchaVerify}
            className="contactForm-content__buttons-captcha"
          />

          <button className="contactForm-content__buttons-btn primary-btn" type="submit">
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
