import React from 'react';
import axios from 'axios';

import Footer from './components/Footer';
import Header from './components/Header';

import Home from './screens/Home';
import { URL } from './url';

const App = () => {
  const [loading, setLoading] = React.useState({ isOthersLoading: false, isItemsLoading: false });
  const [portfolioItems, setPortfolioItems] = React.useState([]);
  const [activeVideo, setActiveVideo] = React.useState(null);
  const [others, setOthers] = React.useState([]);

  const handleActiveVideoClick = (videoId) => {
    setActiveVideo(videoId);
  };

  React.useEffect(() => {
    setLoading((state) => {
      return {
        ...state,
        isOthersLoading: true,
        isItemsLoading: true
      };
    });
    axios
      .get(`${URL}/api/portfolio-items?populate=*`)
      .then(({ data }) => {
        const { data: res } = data;
        const sortedData = res.sort(
          (a, b) => Date.parse(b.attributes.date) - Date.parse(a.attributes.date)
        );
        setPortfolioItems(sortedData);
      })
      .finally(() => setLoading((state) => ({ ...state, isItemsLoading: false })));

    axios
      .get(`${URL}/api/others?populate=*`)
      .then(({ data }) => {
        const { data: res } = data;
        const sortedData = res.sort(
          (a, b) => Date.parse(b.attributes.date) - Date.parse(a.attributes.date)
        );
        setOthers(sortedData);
      })
      .finally(() => setLoading((state) => ({ ...state, isOthersLoading: false })));
  }, []);

  return (
    <div className="App">
      <Header />

      <Home
        portfolioItems={portfolioItems}
        handleActiveVideoClick={handleActiveVideoClick}
        activeVideo={activeVideo}
        others={others}
        isLoading={loading}
      />

      <Footer />
    </div>
  );
};

export default App;
