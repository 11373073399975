import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes, { number, object } from 'prop-types';

import classes from '../styles/sceneBG.module.scss';

import { useAnimationFrame } from '../hooks/useAnimationFrame';
import { shape } from './shape';

export const Scene2D = ({shapesCollection, height, width, amplitude}) => {

    const canvasRef = useRef();
    const contextRef = useRef();
    const speed = 0.07;

    useEffect(() => {
        const canvas = canvasRef.current;
        if(!canvas){
            return;
        }

        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext('2d');
        if(!context){
            return;
        }

        context.filter = 'blur(30px)';
        contextRef.current = context;
    }, [height, width]);

    const shapes = useMemo(() => {
        return shapesCollection.map(item => shape([...item.collection], item.color, amplitude));
    }, [amplitude, shapesCollection]);

    const drawShapes = () => {
        contextRef.current.clearRect(0, 0, width, height);
        shapes.forEach(shape => shape(contextRef.current, speed));
    };

    useAnimationFrame(drawShapes, 24);

    return (
        <canvas ref={canvasRef} className={classes.canvas}/>
    );
};

Scene2D.propTypes = {
    shapesCollection: PropTypes.array,
    width: number,
    height: number,
    amplitude: object,
}

Scene2D.defaultProps = {
    shapesCollection: [],
    width: 500,
    height: 800,
    amplitude: {x: 60, y: 60},
}
