import React, { useCallback, useRef } from 'react';
import { Route } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import '../styles/_modal.scss';

import closeSrc from '../assets/icons/close.png';

import WorkPreview from '../screens/WorkPreview';

export const WorkModal = ({ portfolioItems, onClick }) => {
  const scrollContRef = useRef();

  const handleToInfo = useCallback(() => {
    scrollContRef.current.scrollTo({ top: 600, behavior: 'smooth' });
  }, []);

  return (
    <Route
      path="/works/:id"
      exact
      render={() => (
        <div className="workModal">
          <div className="workModal-container">
            <div ref={scrollContRef} className="workModal-container-workscroll">
              <WorkPreview
                portfolioItems={portfolioItems}
                onClick={onClick}
                toInfo={handleToInfo}
              />
            </div>
          </div>

          <HashLink to={'/#works'}>
            <button className="workModal-close" onClick={onClick}>
              <img src={closeSrc} alt="close" className="workModal-close-cross" />
              close
            </button>
          </HashLink>
        </div>
      )}
    />
  );
};
