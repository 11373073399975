export const headerShape = [
    {
        collection: [
            {pointX: 50, pointY: 50},
            {pointX: 250, pointY: 100},
            {pointX: 250, pointY: 150},
            {pointX: 300, pointY: 200},
            {pointX: 200, pointY: 300},
            {pointX: 150, pointY: 450},
            {pointX: 300, pointY: 650},
            {pointX: 170, pointY: 600},
            {pointX: 40, pointY: 500},
            {pointX: 20, pointY: 250},
            {pointX: 50, pointY: 50},
        ],
        color: '#b73d88',
    }
]

export const experienseShape = [
    {
        collection: [
            {pointX: 500, pointY: 50},
            {pointX: 500, pointY: 500},
            {pointX: 400, pointY: 500},
            {pointX: 150, pointY: 600},
            {pointX: 200, pointY: 400},
            {pointX: 200, pointY: 350},
            {pointX: 250, pointY: 250},
            {pointX: 400, pointY: 100},
            {pointX: 500, pointY: 50},
        ],
        color: '#8C1BB0',
    },
    {
        collection: [
            {pointX: 200, pointY: 350},
            {pointX: 300, pointY: 400},
            {pointX: 350, pointY: 500},
            {pointX: 100, pointY: 650},
            {pointX: 200, pointY: 400},
            {pointX: 200, pointY: 350},
        ],
        color: '#DA4C9F',
    }
]

export const workShape = [
    {
        collection: [
            { pointX: 0, pointY: 50 },
            // { pointX: 0, pointY: 500 },
            // { pointX: 0, pointY: 600 },
            { pointX: 0, pointY: 400 },
            { pointX: 100, pointY: 400 },
            { pointX: 140, pointY: 350 },
            // { pointX: 175, pointY: 300 },
            { pointX: 250, pointY: 250 },
            { pointX: 325, pointY: 300 },
            { pointX: 360, pointY: 325 },
            // { pointX: 350, pointY: 250 },
            // { pointX: 325, pointY: 150 },
            { pointX: 250, pointY: 115 },
            { pointX: 175, pointY: 125 },
            { pointX: 100, pointY: 200 },
            { pointX: 50, pointY: 125 },
            { pointX: 0, pointY: 50 }
        ],
        color: '#8C1BB0',
    },
    {
        collection: [
            { pointX: 0, pointY: 200 },
            { pointX: 75, pointY: 250 },
            { pointX: 175, pointY: 200 },
            { pointX: 225, pointY: 150 },
            { pointX: 175, pointY: 250 },
            { pointX: 0, pointY: 300 },
            { pointX: 0, pointY: 200 }
        ],
        color: '#EE7DFD', // color: '#FCBCF0',
    },
    {
        collection: [
            { pointX: 750, pointY: 50 },
            { pointX: 715, pointY: 150 },
            { pointX: 675, pointY: 250 },
            { pointX: 600, pointY: 300 },
            { pointX: 500, pointY: 400 },
            { pointX: 400, pointY: 350 },
            { pointX: 500, pointY: 400 },
            { pointX: 575, pointY: 450 },
            { pointX: 650, pointY: 425 },
            { pointX: 750, pointY: 350 },
            { pointX: 750, pointY: 50 }
        ],
        color: '#8C1BB0',
    },
    {
        collection: [
            { pointX: 750, pointY: 100 },
            { pointX: 715, pointY: 180 },
            { pointX: 675, pointY: 230 },
            { pointX: 600, pointY: 330 },
            { pointX: 500, pointY: 370 },
            { pointX: 575, pointY: 380 },
            { pointX: 650, pointY: 350 },
            { pointX: 750, pointY: 250 },
            { pointX: 750, pointY: 100 }
        ],
        color: '#014FF9',
    },
]