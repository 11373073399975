import React, { useEffect } from "react";

import "../styles/_videoPreview.scss";

import arrowSrc from "../assets/icons/arrow.png";
import closeSrc from "../assets/icons/close.png";
import dribbleSrc from "../assets/dribble.svg";

const VideoPreview = ({handleActiveVideoClick, activeVideo, others}) => {
  const currentIdx = others.findIndex(item => item.id === activeVideo);
  const videoSrc = others[currentIdx].attributes.media.data[0].attributes.url;
  const type = others[currentIdx].attributes.media.data[0].attributes.mime;
  const nextVideoId = (currentIdx + 1) > others.length - 1 ? others[0].id : others[currentIdx + 1].id;
  const prevVideoId = (currentIdx - 1) < 0 ? others[others.length - 1].id : others[currentIdx - 1].id;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'auto');
  }, []);

  return (
    <div className="videoPreview videoPreview-is-open">
      <div className="blackout"></div>
      <div className="container">
        <div className="videoPreview-wrap">
          <div className="videoPreview-wrap__nav left">
            <div className="secondary-btn arrow" onClick={() => handleActiveVideoClick(prevVideoId)}>
              <img src={arrowSrc} alt="arrow" />
            </div>
          </div>
          <div className="videoPreview-wrap__content">
          <div className="workPreview-wrap__content-mobileOnly">
            <a className="dribble-link" href={others[currentIdx].attributes.dribbble}>
              <img src={dribbleSrc} alt="icon" id="dribble" />
            </a>
            <div className="secondary-btn arrow left" onClick={() => handleActiveVideoClick(prevVideoId)}>
              <img src={arrowSrc} alt="arrow" />
            </div>
            <div className="secondary-btn arrow" onClick={() => handleActiveVideoClick(nextVideoId)}>
              <img src={arrowSrc} alt="arrow" />
            </div>
            <div className="secondary-btn close-btn" onClick={() => handleActiveVideoClick(null)}>
              <img src={closeSrc} alt="close" />
            </div>
          </div>
            <div className="videoPreview-wrap__content-body">
              {
                type === "video/webm" ?
                (
                  <video className="videoPreview-wrap__content-body__video" width="100%" height="100%" autoPlay controls key={videoSrc}>
                    <source src={`${videoSrc}`} type="video/webm" />
                    Your browser does not support the video tag.
                  </video>
                ) : <img className="videoPreview-wrap__content-body__video" src={`${videoSrc}`} alt="img"/>
              }
            </div>
          </div>
          <div className="videoPreview-wrap__nav right">
            <a className="dribble-link" href={others[currentIdx].attributes.dribbble}>
              <img src={dribbleSrc} alt="icon" id="dribble" />
            </a>
            <div className="secondary-btn close-btn" onClick={() => handleActiveVideoClick(null)}>
              <img src={closeSrc} alt="close" />
            </div>
            <div className="secondary-btn arrow" onClick={() => handleActiveVideoClick(nextVideoId)}>
              <img src={arrowSrc} alt="arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPreview;
