import React, { useState, useEffect } from "react";
import { Pagination } from '@mui/material';
import { number } from 'prop-types';
import { func } from "prop-types";

export const WorkPagination = ({itemsLenght, itemsPerPage, onChange}) => {

    const [pagesCount, setPagesCount] = useState(6);

    useEffect(() => {
        setPagesCount(Math.ceil(itemsLenght / itemsPerPage));
    }, [itemsLenght, itemsPerPage]);

    return (
        <Pagination 
            count={pagesCount}
            shape="rounded"
            color="primary"
            defaultPage={1}
            siblingCount={0}
            sx={{
                "& .MuiPaginationItem-page": {border: "1px solid #FF383D"}
            }}
            onChange={onChange}
        />
    )
}

WorkPagination.propTypes = {
    itemsLenght: number,
    itemsPerPage: number,
    onChange: func,
}

WorkPagination.defaultProps = {
    itemsLenght: 0,
    itemsPerPage: 0,
    onChange: () => {},
}